import React from 'react';
import './BiReports.css';
import refresh from '../../assest/Refresh_Logo_Large (2).jpg'
import zones from '../../assest/Zones Logo-930x310(1) (2).jpg'
import oncore from '../../assest/Oncore Logo.jpeg'
import control from '../../assest/control.png';

let refreshReport = "https://app.powerbi.com/view?r=eyJrIjoiNGVmOGU1OWMtMzliMi00ZWYwLTg1ZGQtZDU1ZmYzZGNkZGNiIiwidCI6ImJjNmY1MDRlLTQyZTYtNDExNS04ODZkLTAxNzUzMzE1MWJhYSJ9&embedImagePlaceholder=true";
let oncoreReport = "https://app.powerbi.com/view?r=eyJrIjoiNjViZWJmNDgtODYwZi00NmFhLWFkZGQtYzQ0YjYwODVlYzk5IiwidCI6ImJjNmY1MDRlLTQyZTYtNDExNS04ODZkLTAxNzUzMzE1MWJhYSJ9";
let zonesReport = "https://app.powerbi.com/view?r=eyJrIjoiYjQ0Nzc5ODgtY2UwYy00YTM4LTlmZGItOGQ3M2U3Y2JhYTlkIiwidCI6ImJjNmY1MDRlLTQyZTYtNDExNS04ODZkLTAxNzUzMzE1MWJhYSJ9&embedImagePlaceholder=true";
let controlReport = "https://app.powerbi.com/view?r=eyJrIjoiODk0NWQxOGQtMWNkMi00OTBkLTliNjYtZDViMGU3OGE1ZmY0IiwidCI6ImJjNmY1MDRlLTQyZTYtNDExNS04ODZkLTAxNzUzMzE1MWJhYSJ9";

let wsiReport = "";

const imgLogo = { width: "150px",height:"50px" ,maxHeight:"50px",alignItems:"center",display:"flex"}
const controlLogo = { width: "150px", height: "50px" ,maxHeight:"50px" ,alignItems:"center",display:"flex"}
const wsiLogo = { width: '50px'  ,alignItems:"center",display:"flex" ,maxHeight:"50px"}

const imgLogoImg = { width: "150px",height:"50px" }
const wsiLogoImg = { width: '50px' }
const controlLogoImg = { width: "150px", height: "50px" ,maxHeight:"30px"}

const Tab = ({ src, onClick, style, disabled,imgStyle }) => {
    return (
        <div className='users-login-tabs'>
            <div className='user-login-tab'>
                <div className=''>
                    <div style={style}>
                    <img src={src} style={imgStyle} alt='logo' />
                    </div>
                </div>
                <button disabled={disabled} style={{ background: disabled ? "#EEEEEE" : "", color: disabled ? "black" : "" }} onClick={onClick} className='redirect-btn'>View Report</button>
            </div>
        </div >
    )
}


const BiReports = () => {
    const handleClickReports = (id) => {
        if (id === 'refresh') {
            window.open(refreshReport, "_blank");
        }
        if (id === 'oncore') {
            window.open(oncoreReport, "_blank");
        }
        if (id === 'zones') {
            window.open(zonesReport, "_blank");
        }
        if (id === 'wsi') {
            window.open(wsiReport, "_blank");
        }
        if (id === 'control') {
            window.open(controlReport, "_blank");
        }
    };

    return (
        <>
            <div className="bireport_container">
                <div className="bireport_mainDiv">
                    <div className='left-div'>Business Intelligence Report</div>
                    <div className='org-btns'>
                        <Tab disabled={false} onClick={() => handleClickReports("refresh")} companyName={"REFRESH"} src={refresh} style={imgLogo} imgStyle={imgLogoImg} />
                        <Tab disabled={false} onClick={() => handleClickReports("zones")} companyName={"ZONES"} src={zones} style={imgLogo} imgStyle={imgLogoImg} />
                        <Tab disabled={false} onClick={() => handleClickReports("oncore")} companyName={"ONCORE"} src={oncore} style={imgLogo} imgStyle={imgLogoImg} />
                        <Tab disabled={false} onClick={() => handleClickReports("control")} companyName={"CONTROL"} src={control} style={controlLogo} imgStyle={controlLogoImg} />
                        <Tab disabled={true} onClick={() => handleClickReports("wsi")} companyName={"WE SORT IT"} src={"https://cdn.prod.website-files.com/64053336ec8bf72cbd75a9d1/6568e432d84ced6e55865d40_We%20Sort%20It-Parent-Services.svg"} style={wsiLogo} imgStyle={wsiLogoImg} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default BiReports;
